.Footer {
  background-color: var(--dark);
  height: 330px;
}
.Footer__greylinetop {
  height: 1px;
  width: 100%;
  background-color: #d1cbc620;
  margin-bottom: 50px;
}
.Footer__margin {
  margin-inline: 250px;
}
.Footer__title p {
  color: #6f6f6f;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 11px;
}

.Footer__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.Footer__content {
}
.Footer__content > p {
  color: var(--grey);
  font-size: 11px;
}

.Footer__content > a > p {
  color: var(--grey);
  font-size: 11px;
}

.logoLCFR > img {
  width: 64px;
}
.Footer__flex1 {
  width: 335px;

  color: var(--grey);
}
.Footer__flex1 p {
  font-size: 11px;
  flex: 1;
}
.Footer__flex2 {
  flex: 1;
}
.Footer__flex3 {
  flex: 1;
}

.Footer__flex4 {
  flex: 1;
}

.insta {
  margin-top: 10px;
  color: var(--grey);
}

.Container__stroke {
  width: fit-content;
  font-size: 11px;

  border: 1px #363534 solid;
  border-radius: 5px;
  padding-inline: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 10px;
}

@media screen and (max-width: 1280px) {
  .Footer {
    background-color: var(--dark);
    height: 100%;
  }
  .Footer__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }
  .Footer__margin {
    margin-inline: 0px;
  }
  .Footer__flex1 {
    width: 100%;

    color: var(--grey);
  }

  .Container__stroke {
    font-size: 11px;
    width: 100%;
    opacity: 50%;
    border: 0px #363534 solid;
    border-radius: 5px;
    padding-inline: 10px;
    padding-top: 5px;
    padding-bottom: 25px;
    margin-top: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
  }
  .Footer__white {
    padding-bottom: 20px;
  }
}
