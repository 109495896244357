
.CTA {
    background-color: var(--primary);
    height: 42px;
    width: 230px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    color: white;
    border: none;
    outline: none;
  }
  .CTA p {
    color: white;
    letter-spacing: 0em;
    font-size: 16px;
    font-weight: 700;

  }
  
  .CTA.disabled {
    background-color: rgb(206, 206, 206);
    cursor: not-allowed;
    opacity: 0.2;
  }
  