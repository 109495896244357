.FreeTrial {
    width: 200px;
    height: 50px;
    border-radius: 10px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(115, 148, 208, 0.05) 0px 0.25em 1em;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 20px;
    background-color: var(--dark);
    overflow: hidden;
    z-index: 990;
    opacity: 0;   
    transform: translateX(100%); 
    animation: slideIn 0.2s forwards; /* Animation de glissement */
  }

  @keyframes slideIn {
    0% {
      transform: translateX(100%); /* Commence à droite de l'écran */
      opacity: 0; /* Début invisible */
    }
    100% {
      transform: translateX(0); /* Se déplace vers la position d'origine */
      opacity: 1; /* Deviens visible */
    }
  }
  
  .FreeTrial::after {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    );
    transform: rotate(-45deg);
    animation: shineAnimation 2s infinite;
  }
  
  @keyframes shineAnimation {
    0% {
      transform: translateX(-100%) translateY(-100%) rotate(-45deg);
    }
    100% {
      transform: translateX(100%) translateY(100%) rotate(-45deg);
    }
  }
  
  .FreeTrial__content {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  
  .FreeTrial__content__icon {
    color: white;
    margin-left: -10px;
    margin-right: 4px;
    margin-top: 11px;
    animation: iconZoomAnimation 1s ease infinite;
  }
  
  .FreeTrial__content p {
    margin-top: 16px;
    color: white;
    font-weight: 500;

  }
  
  @keyframes iconZoomAnimation {
    0%,
    100% {
      transform: scale(0.75);
    }
    50% {
      transform: scale(0.8);
    }
  }
  