.UserProfile {
  max-width: 200px;
padding-inline: 24px;
}
.UserProfile p {
  color: white;
}

.UserProfile__ID p {
  font-size: 12px;
}
