.Enroll__layout {
  background-color: var(--grey);
  padding-top: 24px;
padding-bottom: 32px;
}

.Enroll__block {
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: var(--shadow);
  background-color: white;
  padding-inline: 24px;

}

.Enroll__objectives {
  margin-top: -20px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: row;
}

.Enroll__objectives__icon {
  margin-top: 12px;
  color: var(--primary);
  scale: 0.8;
  margin-right: 4px;
}

.Enroll__headingimg {
  display: flex;
  flex-direction: row;
}

.Enroll__img img {
  margin-right: 24px;
  margin-top: 8px;
  width: 160px;
}
.Sticky__button {
  display: none;
}

.Enroll__participants__txt {
  margin-right: 6px;
}

.Enroll__courseinfo {
  margin-top: 20px;
  margin-bottom: -20px;
}

@media screen and (max-width: 840px) {
  .Enroll__block {
   margin-inline: -24px;
   border-radius: 0px;
  }
  .Enroll__layout {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .Enroll__objectives__icon {
    margin-top: 10px;
    color: var(--primary);
    scale: 0.8;
    margin-right: 4px;
  }
  .Enroll__img {
    display: flex;
    justify-content: center;
  }

  .Enroll__headingimg {
    display: flex;
    flex-direction: column;
  }

  .Sticky__button {
    display: block;
    z-index: 9999;
    position: fixed;
    bottom: -100px; /* position initiale hors de la vue */
    right: 20px;
    left: 20px;
    margin: 0 auto; /* Centre le bouton horizontalement */
    transition: bottom 0.3s ease-in-out, visibility 0.3s ease-in-out;
    visibility: hidden;
    width: calc(
      100% - 40px
    ); /* Ajuste la largeur en tenant compte des marges */
  }

  .Sticky__button.visible {
    bottom: 20px; /* position lorsque le bouton est visible */
    visibility: visible;
  }

  .Sticky__button__align {
    display: flex;
    justify-content: center;
  }
}
