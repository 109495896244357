.Motdujour {
    background-color: #DFEDFF;
    height: 60px;
    width: 100%;
    margin-top: -18px;
    color: #0370FF;
}
.Motdujour__contenu p {
    padding-top: 16px;
    font-weight: bold;
    text-align: center;
}