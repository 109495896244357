.DropdownMenu {
  position: relative;
  width: 100%;
}

.DropdownMenu p {
  margin-bottom: 8px;
}

.DropdownMenu__container {
  background-color: white; /* Couleur de fond */

  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-shadow: var(--shadow);
  transition: border 0.3s ease;
}

.DropdownMenu__container:hover {

}

.DropdownMenu__selected {
  color: #333; /* Couleur du texte sélectionné */
}

.DropdownMenu__arrow {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #a2a2a2; /* Couleur de la flèche */
  transition: transform 0.3s ease;
}

.DropdownMenu__arrow.open {
  transform: rotate(180deg);
}

.DropdownMenu__options {
  position: absolute;
  top: calc(100% + 5px);
  width: 100%; /* Largeur à 100% */
  background-color: white; /* Couleur de fond */

  border-radius: 5px;
  box-shadow: var(--shadow);
  z-index: 1000;
  animation: dropdown-open 0.3s ease;
}

@keyframes dropdown-open {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.DropdownMenu__option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.DropdownMenu__option:hover {
  background-color: #f3f3f1; /* Couleur de fond au survol */
}
