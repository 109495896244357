
  .CallToAction100 {
    background-color: var(--dark);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    height: 54px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    color: white;
    border: none;
    outline: none;
  }
  .CallToAction p {
    color: white;
    letter-spacing: 0em;
    font-size: 18px;
    font-weight: 600;
    margin-top: 14px;
  }
  
  .CallToAction100_icon {
    margin-top: 14px;
    margin-left: 6px;
  }
  .CallToAction100.disabled {
    background-color: rgb(206, 206, 206);
    cursor: not-allowed;
    opacity: 0.2;
  }
  