.PostStudents__blocks {
    outline: 1px solid rgb(0, 0, 0);
    padding-inline: 24px;
    display: flex;

    flex-direction: row;
}
.PostStudent__leftblock {
height: 600px;
overflow-y: scroll;
    outline: 1px solid rgb(0, 0, 0);
    flex: 3;
}
.PostStudent__rightblock {

    outline: 1px solid rgb(0, 0, 0);
    flex: 1;
}
