.Badge {
  background-color: #0095ff20;
  color: #0095ff;
  text-align: center;
  border-radius: 10px;
  width: fit-content;
  padding-inline: 8px;
  height: 15px;
}

.Badge p {
  padding-top: 1px;
  font-size: 10px;
  font-weight: bold;
}

@media screen and (max-width: 840px) {

}
