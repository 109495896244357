.Etudiants {
  margin-left: 48px;
  margin-top: -60px;
  margin-right: 24px;
}

/* Test.css */
.status-paid {
  background-color: rgb(220, 255, 212);
  color: rgb(0, 186, 19);
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  border-radius: 100px;
  text-align: center;
  max-width: 50px;
}

.status-pending {
  background-color: rgb(213, 213, 213);
  color: rgb(71, 71, 71);
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  border-radius: 100px;
  text-align: center;
  max-width: 70px;
}
