:root {
  --primary: #ff6b00;
  --dark: #272727;
  --grey: white;
  --grey2: #212121;
  --lightgrey: #eeeeee;
  --shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:wght@100;200;300;400;500;600;700;800;900&display=swap');


p {
  font-size: 14px;
  letter-spacing: -0em;
  font-family: 'Bricolage Grotesque', sans-serif;
}
h1 {
  letter-spacing: -0.05em;
  font-family: 'Bricolage Grotesque', sans-serif;
  font-weight: 700;
  font-size: 48px;
}
h2 {
  letter-spacing: -0.05em;
  font-family: 'Bricolage Grotesque', sans-serif;
  font-weight: 700;
  font-size: 28px;
}
h3 {
  font-size: 18px;
  letter-spacing: -0.05em;
  font-weight: 700;
  font-family: 'Bricolage Grotesque', sans-serif;
}
h4 {
  font-size: 16px;
  letter-spacing: -0.05em;
  font-weight: 700;
  font-family: 'Bricolage Grotesque', sans-serif;
}

.App {
  overscroll-behavior: none;
  -webkit-overscroll-behavior: none;
  overflow-x: hidden; /* Définir uniquement la propriété overflow-x */
  overflow-y: auto; /* Utiliser overflow-y: auto; pour activer le défilement vertical si nécessaire */
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: "Inter", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tinos:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Georgia:wght@700&display=swap");

a {
  text-decoration: none;
}

.CTA {
  display: flex;
  text-decoration: none;
  color: white;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 50px;
  background-color: #ff6b00;
  border-radius: 4px;
  font-size: 18px;
  border: solid black 0px;
}
.CTA_Secondary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 50px;
  background-color: #ffffff;
  border: solid black 2px;
  border-radius: 4px;
  font-size: 18px;
}

.CTA_Secondary > p {
  margin: 0; /* Ajustez selon vos besoins */
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

.CTA > p {
  margin: 0; /* Ajustez selon vos besoins */
  font-weight: 600;
  text-align: center;
  font-size: 18px;
  color: white;
}

.Aligncenter {
  display: flex;
  margin-left: -8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Alignright {
  display: flex;
  margin-right: 8px;
  align-items: right;
  justify-content: right;
  text-align: right;
}

.h120 {
  width: 100%;
  height: 120px;
}

.h64 {
  width: 100%;
  height: 64px;
}
.h48 {
  width: 100%;
  height: 48px;
}
.h32 {
  width: 100%;
  height: 32px;
}

.h24 {
  width: 100%;
  height: 24px;
}

.h16 {
  width: 100%;
  height: 16px;
}
.h8 {
  width: 100%;
  height: 8px;
}

.h4 {
  width: 100%;
  height: 4px;
}
.legal > p {
  font-size: 10px;
  color: grey;
  text-align: center;
}

.skeleton__container {
  width: calc(100% - 32px); /* 100% width minus 2 * 16px margin */
  margin: 0 auto; /* Center the container */
}

.Skeleton {
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  height: 60px;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  animation: skeleton-animation 0.5s ease-in-out infinite;
}

.Skeleton2 {
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  height: 700px;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  animation: skeleton-animation 0.5s ease-in-out infinite;
}

@keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.hover {
  cursor: pointer;
}



@media screen and (max-width: 840px) {
  h1 {
  line-height: 1;

  }
}
.txt {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  letter-spacing: -0em;
  font-weight: bold;

  margin-top: -20px;
}

.Margin__global {
  padding-inline: 160px;
}
@media screen and (max-width: 840px) {
  .Margin__global {
    padding-inline: 24px;
  }
}

.icon__txt {
  display: flex;
  flex-direction: row;
  margin-top: -15px;

}
.icon__txt__icon {

margin-top: 13px;
scale: 0.9;
margin-right: 8px;

}
.icon__txt__txt {


}