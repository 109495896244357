.OurLevels {
  background-color: var(--lightgrey);
  margin-top: -24px;
}

.OurLevels h2 {
  padding-top: 40px;
  color: var(--dark);
  text-align: center;
}

.OurLevels__subtitle {
  color: grey;
  margin-top: -16px;
  text-align: center;
  line-height: 1.8;
  padding-bottom: 40px;
}

.OurLevel__block {
  width: 300px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}
.OurLevel__block__left {
  flex: 1;
}
.OurLevel__block__right {
  flex: 4;
  margin-left: -5px;
}

.OurLevel__block__right p {
  color: grey;
  margin-top: -14px;
}
.OurLevel__block__right h3 {
  color: var(--dark);
}

.OurLevel__block__square1 {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 47px;
  background-color: #5fbcff;
  border-radius: 5px;
}
.OurLevel__block__square2 {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 47px;
  background-color: #0094ff;
  border-radius: 5px;
}
.OurLevel__block__square3 {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 47px;
  background-color: #005ea3;
  border-radius: 5px;
}
.OurLevel__block__square4 {
  margin: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  width: 47px;
  background-color: #00477a;
  border-radius: 5px;
}

.OurLevel__block__square1 p,
.OurLevel__block__square2 p,
.OurLevel__block__square3 p,
.OurLevel__block__square4 p {
  font-size: 28px;
  color: white;
  font-weight: 700;
}

.OurLevels__align {
  display: flex;
  flex-direction: column;
}
.OurLevels__align__center {
  display: flex;
  justify-content: center;
  padding-bottom: 145px;
}

@media screen and (max-width: 485px) {
  .OurLevel__block {
    width: 100%;
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

  }
  .OurLevels__subtitle {

    margin-top: -16px;
    text-align: center;
    line-height: 1.8;
    padding-inline: 40px;
  }
  .OurLevels__align {
    padding-inline: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
