.Login__layout {
    display: flex;
    flex-direction: row;
    background-color: #F9EEE7;
  
  }
  .Login__card {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    margin-inline: 48px;
  
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    margin-top: 100px;
    flex: 2;
    margin-bottom: 200px;
  }
  
  .Login__left {
    flex: 3;
  }
  
  .Login__left img {
    margin-left: 60px;
    margin-top:60px;
    width: 95%;
  }
  
  .Login__inputs {
    margin-inline: 24px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .Login__inputs_labels p {
    color: rgb(0, 0, 0);
    font-size: 14px;
  }
  
  .Login__inputs_labels__onerow {
    display: flex;
  
    flex-direction: row;
    width: 100%;
  }
  
  .margin__name {
    margin-right: 16px;
  }
  .Login__inputs_labels__onerow__name {
    flex: 1;
  }
  .Login__inputs_labels__onerow__surname {
    flex: 1;
  }
  
  .Login input {
    width: calc(100% - 16px);
  
    background-color: rgb(236, 236, 236);
    border: none;
    border-radius: 8px;
    padding: 8px;
    font-size: 18px;
    height: 30px;
    text-decoration: none;
    left: 24px;
  }
  
  .Login__inputs__error p {
    color: red;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup is above all content */
  }
  
  .popup-content {
    margin-top: -100px;
    width: 400px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    z-index: 1001; /* Ensure the content of the popup is above the blur */
    padding: 20px; /* Add some padding */
    text-align: center; /* Center text */
  }
  .popup-content__button {
    display: flex;
    justify-content: center;
    flex: 1;
  }
  
  .popup__content__icon {
    scale: 3;
  
    color: var(--primary);
  }
  .popup__content__sub p {
    margin-top: -10px;
  }
  .Login__cta {
    margin-top: -40px;
  }
  
  .Login__Login {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }
  .Login__inputs_labels__tagline {
    margin-top: -14px;
  }
  .Login__inputs__greylabels p {
    color: grey;
  }
  
  @media screen and (max-width: 840px) {
    .popup-content {
      margin-top: -180px;
      width: 100%;
      margin-inline: 16px;
      border-radius: 10px;
      background-color: rgb(255, 255, 255);
      z-index: 1001; /* Ensure the content of the popup is above the blur */
    }

    .Login__layout {
      padding-inline: 0px;
      display: flex;
      flex-direction: column;
  
      background-color: rgb(242, 242, 242);
    }
    .Login__left img {
      margin-left: 0px;
      margin-top: 0px;
      width: 100%;
  
    }
    .Login__card {
      box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
        rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
      margin-inline: 0px;
      padding-top: 16px;
      border-radius: 0px;
      background-color: rgb(255, 255, 255);
      margin-top: 0px;
   
      flex: 2;
      margin-bottom: 0px;
    }
    .Login__left {
      flex: 3;
  
  
    }
    .Login__inputs {
      margin-inline: 16px;
      display: flex;
      flex-direction: column;
    }
    .Login input {
      width: calc(100% - 16px);
  
      background-color: rgb(236, 236, 236);
      border: none;
      border-radius: 8px;
      padding: 8px;
      font-size: 18px;
      height: 30px;
      text-decoration: none;
      left: 24px;
    }
    .margin__name {
      margin-right: 0px;
    }
  }
  