.FrenchTest {
  display: flex;
  justify-content: center;
  background-color: white;
}
.FrenchTest__img {
    margin-top: 80px;
  display: flex;
  justify-content: center;
}
.FrenchTest__img img {
  height: 170px;
}
.FrenchTest__h2 {
    margin-top: -10px;
  color: var(--dark);
  text-align: center;
}
.FrenchTest__subtitle {
    margin-bottom: 20px;
    margin-top: -10px;
  text-align: center;
  color: grey;
}
.FrenchTest__button {
  display: flex;
  justify-content: center;
}

.FrenchTest__arrow {
    margin-top: -60px;
    margin-left: 300px;
    scale: 0.8;
    rotate: 40deg;
}