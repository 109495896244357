.FreeClass {
  margin-bottom: 90px;
}

.FreeClass__zone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  padding-inline: 452px;
}
.FreeClass__CTA__input {
  flex: 1;
}

.FreeClass_Mail_input {
  background-color: white;
  outline: none;
  border: none;
  border-radius: 7px;
  padding-left: 10px;
  height: 40px;
  text-decoration: none;
  left: 24px;
  font-size: 16px;
  color: #696969;
  margin-left: 24px;
  margin-right: 16px;
  width: 250px;
}
.FreeClass__CTA {
  flex: 1;
}

.FreeClass h2 {
  color: #d1cbc6;
  text-align: center;
  margin-top: 270px;
  margin-bottom: 40px;
}

.FreeClass__sentence {
  display: flex;
  justify-content: center;
  color: #8f8f8f;
  margin-top: 20px;
}

.FreeClass__text__icon__ai {
  margin-top: 10px;
  color: #8f8f8f;
  scale: 0.7;
}
.FreeClass__center {
  display: flex;
  justify-content: center;

  margin-left: 460px;
}
.FreeClass__center img {
  margin-top: -75px;
  rotate: -20deg;
}

@media screen and (max-width: 840px) {
  .FreeClass__zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
    padding-inline: 0px;
  }

  .FreeClass__h2__mobile__align {
    display: flex;
    justify-content: center;
  }

  .FreeClass h2 {
    color: #d1cbc6;
    width: 280px;

    margin-top: 220px;
    margin-bottom: 40px;
  }
  .FreeClass__center {
    display: flex;
    justify-content: center;
    margin-left: 220px;
  }
  .FreeClass__center img {
    margin-top: -70px;
    rotate: -20deg;
  }
  .FreeClass__input {
    display: flex;
    justify-content: center;
    padding-bottom: 8px;
    margin-left: 3px;
  }
  .FreeClass_Mail_input {
    background-color: white;
    outline: none;
    border: none;
    border-radius: 7px;
    padding-left: 10px;
    height: 40px;
    text-decoration: none;
    left: 24px;
    font-size: 16px;
    color: #696969;
    margin-left: 0px;
    margin-right: 0px;
    width: 214px;
  }
  .FreeClass__CTA {
    display: flex;
    justify-content: center;
  }
}
