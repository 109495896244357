.SidePannel {
  background-color: #111111;
  width: 250px;
  height: 100vh;
  cursor: pointer;
  
}

.SidePannel__logo img {
  height: 50px;
  width: 50px;
  padding: 24px;
}

.SidePannel__greyline {
  background-color: #5a5a5a;
  height: 1px;
  width: 100%;
}

.SidePannel__menu {
  padding-top: 8px;
  margin-left: 24px;
}

.SidePannel__menu p {
  margin-bottom: 24px;
  color: gray; /* Couleur par défaut des éléments inactifs */
  position: relative;
  padding-right: 20px; /* Espace pour la barre rouge */
}

.SidePannel__menu p.active {
  color: white; /* Couleur du menu actif */
}

.SidePannel__menu p.active::after {
  content: '';
  position: absolute;
  right: 0; /* Positionnement à droite */
  top: -10px;
  bottom: -10px;

  width: 4px; /* Largeur de la barre */
  background-color: rgb(255, 255, 255);
}


.SidePannel__bottomInfo {
position: absolute;
bottom: 0;
}