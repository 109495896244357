.Aboutus__img {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.Aboutus__img img {
  height: 300px;
}

.Aboutus__content {
  margin-top: 64px;

  background-color: var(--primary);
  height: 250px;
  display: flex;
  justify-content: center;
  padding-inline: 16px;
}

.Aboutus__content h1 {
  width: 1000px;
  color: white;
  text-align: center;
}

@media screen and (max-width: 840px) {
  .Aboutus__content {
    margin-top: 64px;

    background-color: var(--primary);
    height: 350px;
    display: flex;
    justify-content: center;
    padding-inline: 16px;
  }
  .Aboutus__content h1 {
    margin-top: 100px;
    width: 1000px;
    font-size: 32px;
    color: white;
    text-align: center;
  }
  .Aboutus p {
    margin-left: -8px;
  }
}

.Aboutus__CTA {
  display: flex;
  justify-content: center;
}
