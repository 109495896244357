.AccountCreated {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AccountCreated__buttons {
  width: 300px;

  flex-direction: column;
  display: flex;
  justify-content: center;
}

.AccountCreated__icon {
  margin-top: 120px;
  background-color: #00ca1830;
  color: #00ca18;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
.AccountCreated__txt {
  text-align: center;
  padding-inline: 24px;
  margin-bottom: 50px;
}

.AccountCreated__txt span {
  color: gray;
}
