.AdminDashboard {
  display: flex;
  flex-direction: row;
  height: 100vh; /* Utilisation de 100vh pour prendre toute la hauteur de l'écran */
  background-color: #fcf9f4;
  overflow: hidden;
}

.AdminDashboard__left {
  flex: 1;
}

.AdminDashboard__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-right: 24px;
  justify-content: end;
}

.AdminDashboard__margin {
  margin-right: 16px;
}

