.CardSelector {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: var(--shadow);
  cursor: pointer;
  display: flex;

  flex-direction: column;
}
.CardSelector__content {
  padding-inline: 16px;
}

.CardSelector.selected {
  outline: 2px solid #0071e3;
background-color: #0071e310;
}

.CardSelector__header {
  display: flex;
  margin-top: -8px;
  margin-bottom: -20px;
}
.CardSelector__header__title {
}
.CardSelector__header__selection {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  margin-right: -10px;
}

.CardSelector__info {
  margin-top: 21px;
  font-size: 16px;
  color: #0071e3; /* Couleur bleue pour indiquer la sélection */
}


.CardSelector__bottomContainer {
  margin-top: -20px;
  margin-bottom: -16px;

  display: flex;
  flex-direction: row;
}
.CardSelector__price {

}
.CardSelector__badge {
  margin-top: 4px;
margin-left: auto;

}