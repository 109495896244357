.Frenchquiz__questions__container {
}
.Frenchquiz__questionslayout {
  padding-inline: 160px;
}

.FrenchQuiz__popup {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Assurez-vous que la popup est au-dessus du contenu */
}

.FrenchQuiz__popup-content {
  margin-top: -100px;
  width: 680px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  z-index: 1001; /* Pour s'assurer que le contenu de la popup est au-dessus du flou */
}

.blur {
  filter: blur(5px);
}

.FrenchQuiz__Popup__quizz__txt {
  margin-bottom: 24px;
  margin-top: -10px;
  margin-left: 24px;
  margin-right: 24px;
}

.FrenchQuiz__Popup__quizz__logo {
  margin-left: -15px;
  display: flex;
  justify-content: left;
}
.FrenchQuiz__Popup__quizz__logo img {
  margin-top: 24px;
  margin-left: 24px;
  height: 60px;
}

.FrenchQuiz__Popup__quizz__block {
  display: flex;
}

.FrenchQuiz__Popup__quizz__input {
  margin-right: 16px;
  margin-left: 24px;
}

.FrenchQuiz__Popup__quizz__input input {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

  width: calc(100% - 16px);
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 8px;
  font-size: 18px;
  height: 38px;
  text-decoration: none;
  left: 24px;
  width: 400px;
}
.FrenchQuiz__Popup__quizz__block__cta {
  width: 100%;
  margin-right: 24px;
}

@media screen and (max-width: 840px) {
  .FrenchQuiz__popup-content {
    margin-top: 0px;
    width: 100%;
    margin-inline: 16px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    z-index: 1001; /* Pour s'assurer que le contenu de la popup est au-dessus du flou */
  }
  .FrenchQuiz__Popup__quizz__block {
    display: flex;
    flex-direction: column;
  }
  .FrenchQuiz__Popup__quizz__input input {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    width: calc(100% - 16px);
    background-color: white;
    border: none;
    border-radius: 8px;
    padding: 8px;
    font-size: 18px;
    height: 38px;
    text-decoration: none;
    width: 100%;

  }
  .FrenchQuiz__Popup__quizz__input {
    margin-right: 40px;
  }
  .FrenchQuiz__Popup__quizz__block__cta {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  

  }
  .Frenchquizz__align {
    width: 100%;
    padding-inline: 24px;
  }
}
.Frenchquiz__question__content {
  font-weight: bold;
  font-size: 24px;
  margin-top: -32px;
}

.Frenchquiz__img {
  display: flex;
  justify-content: center;
}
.Frenchquiz__img img {
  height: 200px;
}

.Frenchquiz_img_skeleton {
  width: 100%;
  background-color: #eee;
  border-radius: 5px;
  height: 200px;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  animation: skeleton-animation 0.5s ease-in-out infinite;
}

.Frenchquiz__bottomblock {
  position: fixed;
  padding-inline: 150px;

  width: calc(100% - 300px);
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  bottom: 0;
}

.Frenchquiz__button {
  background-color: #ff6b00;
  margin-inline: 10px;

  height: 54px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  color: white;
  border: none;
  outline: none;
}
.Frenchquiz__button__icon__next {
  margin-top: 14px;
  margin-left: 6px;
}
.Frenchquiz__button__icon__previous {
  margin-top: 14px;
  margin-right: 6px;
  margin-left: -14px;
}

.Frenchquiz__button.disabled {
  opacity: 0.2;
}

.Frenchquiz__button p {

  letter-spacing: 0em;
  font-size: 18px;
  font-weight: 600;
  margin-top: 14px;
  color: rgb(255, 255, 255);
}

.Frenchquiz__option {
  padding: 10px 16px;
  margin: 10px 0;
  border: 2px solid #e4e4e4;
  border-radius: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
}
.Frenchquiz__amount {
  letter-spacing: -1px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
}

.Frenchquiz__back {
  margin-top: 14px;
  margin-right: 4px;
  color: rgb(0, 0, 0);
}

.Frenchquiz__option.selected {
  background-color: #ff6b00;
  border: 2px solid #ff6b00;
  color: #fff;
}

.FrenchQuiz__result__img {
  display: flex;
  height: 240px;
  justify-content: center;
  padding-top: 30px;
}
@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.FrenchQuiz__result__img img {
  height: 150px;
  animation: zoomIn 0.5s ease-in-out;
}

.FrenchQuiz__result__container {
  margin-top: -80px;
}

.FrenchQuiz__result__container h2 {
  text-align: center;
}

.FrenchQuiz__result__container__tagline {
  margin-top: -10px;
  text-align: center;
  margin-bottom: 30px;
}
.FrenchQuiz__result__container__cta {
  display: flex;
  justify-content: center;
}

.FrenchQuiz__result__content {
  padding-top: 1px;
  padding-bottom: 300px;
  background-color: rgb(244, 244, 244);
  padding-inline: 20px;
  text-align: center;
}

.FrenchQuiz__result__container__tagline__arrow {
  rotate: 30deg;
  display: flex;
  height: 60px;
  justify-content: center;
}

@keyframes rotateBackForth {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.FrenchQuiz__result__container__tagline__arrow img {
  height: 70px;
  animation: rotateBackForth 1s infinite;
}

.FrenchQuiz__result__content__value p {
  color: #ff6b00;
  text-align: center;

  font-weight: bold;
}

.FrenchQuiz__result__input {
  display: flex;
  justify-content: center;
  padding-top: 16px;
}

.FrenchQuiz__result__content input {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

  width: 300px;
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 8px;

  height: 30px;
  text-decoration: none;
  left: 24px;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  z-index: 1000;
}

.progress-bar__fill {
  height: 100%;
  background-color: #ff6b00;
  transition: width 0.4s ease;
}

.FrenchQuiz__Popup__quizz__timer p {
  color: grey;
}

.FrenchQuiz__Popup__quizz__timer__icon {
  margin-top: 14px;

  color: grey;
  margin-right: 3px;
  scale: 0.8;
}

.FrenchQuiz__Popup__quizz__bottom__block {
  height: 100px;
  color: grey;
  margin-top: 48px;
  border-radius: 0px 0px 10px 10px;
  background-color: rgb(238, 238, 238);
}

.FrenchQuiz__Popup__quizz__timer {
  justify-content: center;
  height: 60px;
  display: flex;

  flex-direction: row;
  padding-top: 22px;
}

@media screen and (max-width: 840px) {
  .Frenchquiz__questionslayout {
    padding-inline: 10px;
  }

  .Frenchquiz__bottomblock {
    position: fixed;
    padding-inline: 0px;

    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    bottom: 0;
  }

  .FrenchQuiz__Popup__quizz__timer__icon {
    margin-top: 14px;

    color: grey;
    margin-right: 2px;
    scale: 0.8;
  }
}
