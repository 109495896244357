.CardClass {
  background-color: rgb(255, 255, 255);
  width: 100%;
  border-radius: 5px;
  height: 200px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.CardClass__left {
  padding-top: 10px;
  padding-bottom: 10px;

  width: 280px;
}

.CardClass__right {
  width: 100%;
}
.CardClass__right__title {
  color: var(--dark);
}
.CardClass__right__subtitle {
  margin-top: -17px;
  font-weight: 500;
  color: grey;
}
.CardClass img {
  height: 100%;
}
.CardClass__right__badge {
  margin-top: -4px;
}
.CardClass__right__bottom {
  padding-top: 80px;
}
.CardClass__right__bottom__arrow {
  color: var(--dark);
  margin-right: 10px;
  display: flex;
  justify-content: right;
}

@media screen and (max-width: 1285px) {
  .CardClass img {
    height: 80%;
  }
  .CardClass {
    width: 100%;
    border-radius: 5px;

    height: 200px;
    display: flex;
    flex-direction: row;
  }
  .CardClass__left {
    padding-top: 10px;

    padding-bottom: 10px;
flex: 1;
  }
  .CardClass__right {
    width: 100%;

    margin-left: 16px;
  }
  .CardClass__right__bottom {
    margin-right: 12px;
    flex: 1;
  }
  .CardClass__right__bottom__arrow {
    margin-left: auto;

    width: 100%;
 
  }
}
