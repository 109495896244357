.UserInfo {
  background-color: #f5f5f5;
  padding-inline: 100px;
}

.UserInfo__blocks {
  display: flex;
  flex-direction: row;
}
.UserInfo__left {
  flex: 2;
}

.UserInfo__left img {
  width: 100%;
  margin-top: 100px;
}
.UserInfo__right {
  flex: 1;
  border-radius: 10px;
  margin-top: 24px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  padding-inline: 24px;
  margin-bottom: 24px;
}
.UserInfo__input {
  position: relative;
}

.UserInfo__input input {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

  width: calc(100% - 16px);
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 8px;

  height: 20px;
  text-decoration: none;
  left: 24px;
}

.UserInfo__input p {
  color: gray;
}

@media screen and (max-width: 840px) {
  .UserInfo__blocks {
    display: flex;
    flex-direction: column;
  }
  .UserInfo__left img {
    width: 100%;
    margin-top: 20px;
  }
  .UserInfo__right {
    flex: 1;
    border-radius: 10px;
    margin-top: 0px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    padding-inline: 24px;
    margin-bottom: 24px;
  }
  .UserInfo {
    background-color: #f5f5f5;
    padding-inline: 0px;
  }
}


.Userinfo__login {
  text-align: center;
  color: black;
  margin-top: 24px;
  margin-bottom: 24px;
}