.ValueProposition {
  position: relative;
  background-color: var(--primary);
}
.ValueProposition__container {
  display: flex;
  justify-content: center;
}
.ValueProposition__title {
  margin-top: 112px;
}

.ValueProposition__title__h1 {
  z-index: 999;
  position: relative;
  color: var(--grey);
}

.ValueProposition__title span {
  color: white;
}

.ValueProposition__rectangle {
  display: flex;
  margin-left: auto;
  z-index: 99;
  position: relative;
  rotate: -1deg;
  margin-top: -85px;
  height: 48px;
  background-color: var(--dark);
  width: 268px;
  margin-right: -8px;
}

.ValueProposition__subtitle {
  margin-top: -3px;
  line-height: 1.8;
  text-align: center;
  color: var(--grey);
}
.ValueProposition__arrow img {
  margin-top: -30px;
  margin-left: 555px;
}
.ValueProposition__CTA__zone {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -10px;
  padding-inline: 452px;
}

.ValueProposition_Mail_input {
  background-color: white;
  outline: none;
  border: none;
  border-radius: 7px;
  padding-left: 10px;
  height: 40px;
  text-decoration: none;
  left: 24px;
  font-size: 16px;
  color: #696969;
  margin-left: 24px;
  margin-right: 16px;
  width: 250px;
}

.ValueProposition__CTA__input {
  flex: 1;
}
.ValueProposition__CTA {
  flex: 1;
}
.ValutProposition__text__icon {
  margin-left: -250px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  flex-direction: row;
}
.ValutProposition__text__icon p {
  margin-top: 6px;
}
.ValutProposition__text__icon__ai {
  scale: 0.6;
  margin-right: 3px;
}

.ValueProposition__award {
  display: flex;
  margin-top: 160px;
  justify-content: center;
}

.ValueProposition__award__wing1 {
  margin-right: -20px;
}
.ValueProposition__award__wing2 {
  margin-left: -20px;
}
.ValueProposition__award__middleblock {
}
.ValueProposition__award__middleblock__txt {
  margin-top: -2px;
}
.ValueProposition__award__middleblock__txt p {
  font-size: 8px;
  text-align: center;
  color: var(--grey);
}
.ValueProposition__award__stars {
  margin-top: -14px;
  display: flex;
  flex-direction: row;
  color: #f8df00;
  scale: 0.4;
}

.ValueProposition__3__values {
  justify-content: center;
  display: flex;
  margin-top: 62px;
  margin-bottom: -23px;
  margin-left: 16px;
  flex-direction: row;
}

.ValueProposition__check__text {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}

.ValueProposition__check {
  color: white;
  scale: 0.9;
  margin-right: 3px;
  margin-top: 10px;
}
.ValueProposition__text p {
  color: var(--grey);
}

@media screen and (max-width: 840px) {
  .ValueProposition__title__h1 {
    z-index: 999;
    text-align: center;
    width: 280px;
    position: relative;
    color: var(--grey);
    margin-top: -20px;
  }
  .ValueProposition__title__h1 h1 {
    z-index: 999;
    text-align: center;
    font-size: 32px;
    position: relative;
    color: var(--grey);
  }
  .ValueProposition__rectangle {
    display: flex;
    margin-left: auto;
    z-index: 99;
    position: relative;
    rotate: -1deg;
    margin-top: -52px;
    height: 32px;

    width: 180px;
    margin-right: 50px;
  }
  .ValueProposition__subtitle {
    margin-top: -3px;
    line-height: 1.8;
    margin-inline: 16px;

    color: var(--grey);
  }
  .ValueProposition__subtitle__txt {
    display: flex;
    justify-content: center;

    text-align: center;
  }
  .ValueProposition__subtitle__txt p {
    width: 300px;
  }
  .ValueProposition__arrow img {
    margin-top: -32px;
    margin-left: 250px;
  }

  .ValueProposition__CTA__zone {
    display: flex;
    align-items: center;
   
    justify-content: center;
    flex-direction: column;

    padding-inline: 0px;
    width: 100%;
  }
  .ValueProposition__CTA__input input {
    flex: 1;
    margin-top: 8px;
    justify-content: center;
    width: 218px;
    margin-left: 16px;
  }
  .ValueProposition__CTA {
    margin-top: 8px;
    flex: 1;
    justify-content: center;
  }
  .ValutProposition__text__icon {
    margin-left: -5px;
    margin-top: 10px;

    display: flex;
    justify-content: center;

    flex-direction: row;
  }
  .ValueProposition__award {
    display: flex;
    margin-top: 30px;
    justify-content: center;
  }
  .ValueProposition__3__values {
padding-bottom: 24px;
    justify-content: center;
    display: flex;
    margin-top: 62px;
    margin-left: 16px;
    flex-direction: column;
  }
  .ValueProposition__check__text {
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    margin-bottom: -10px;
  }
  .ValueProposition__check {

    scale: 0.7;
    margin-right: 3px;
    margin-top: 10px;
  }
}
