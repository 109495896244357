.dropdown {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Ombre plus douce */
  border-radius: 8px; /* Coins arrondis */
  overflow: hidden; /* Pour que l'ombre ne déborde pas */
  
}
  
  .dropdown-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdown-menu li {
    padding: 16px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .dropdown-menu li:last-child {
    border-bottom: none;
  }
  
  .accordion-details {
    margin-top: -18px; 
  }
