.FAQ {
  display: flex;
  flex-direction: row;
  color: #d1cbc6;
  margin-top: 55px;
}

@media screen and (max-width: 840px) {
  .FAQ {
    display: flex;
    flex-direction: column;
    color: #d1cbc6;
    margin-top: 55px;
  } 
}


.FAQ__leftblock {
  flex: 1;
}

.FAQ__rightblock {
  margin-top: 20px;
  flex: 1.1;
}

.FAQ__expandable {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  padding: 3px 0;
}

.FAQ__expandable:hover {
    background-color: #44434220;
}

.FAQ__expandable__add {
  margin-left: auto;
  margin-right: 10px;
  margin-top: 10px;
  transition: transform 0.3s ease; /* Animation de transformation */
}

.FAQ__expandable__add:hover {
  transform: scale(1.2); /* Agrandir l'icône au survol */
}

.FAQ__greyline {
  background-color: #444342;
  height: 1px;
  width: 100%;
  transition: width 0.8s ease-out; /* Animation de largeur */
}

.FAQ__leftblock p {
  margin-top: -10px;
}

.FAQ__leftblock span {
  text-decoration: underline;
  color: white;
}

.FAQ__leftblock p:hover {
  color: #f1e5b3; /* Changer la couleur du texte de la question au survol */
}

.FAQ__answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}
.FAQ__expandable__label p {
  padding-left: 10px;
}

.FAQ__answer.visible {
  opacity: 1;
  max-height: 200px;
}

.FAQ__answer p {
    padding-left: 10px;
}

