.CTAPortal {
    background-color: #EBEBEE;
    height: 54px;
    padding-inline: 24px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;
    color: white;
    border: none;
    outline: none;

  }
  
  .CTAPortal p {
    color: black;
    letter-spacing: 0em;
    font-size: 18px;
    font-weight: 600;
    margin-top: 14px;
  }
  

  .CTAPortal.disabled {
    background-color: rgb(206, 206, 206);
    cursor: not-allowed;
    opacity: 0.2;
  }
  
  .CTAPortal_icon {
    color: black;
    margin-top: 14px;
    margin-right: 8px;
  }