.Prosandcons__sentence__arrow {
  color: var(--grey);
  display: flex;
  justify-content: center;
  margin-top: 98px;
  flex-direction: row;
  margin-left: -45px;
}

.Prosandcons__sentence__arrow__txt p {
  font-weight: 600;
  color: var(--dark);
}
.Prosandcons__sentence__arrow__svg {
  position: relative;
  z-index: 100;
  scale: 0.8;
  margin-top: 19px;
  rotate: 11deg;
}

.Prosandcons__container {
  position: relative;
  margin-top: -24px;
  z-index: 99;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Prosandcons__pros {
  margin-right: 45px;
  margin-left: 10px;
  background-color: #2b1b09;
  width: 450px;
  height: 626px;
  border-radius: 10px;
}
.Prosandcons__pros h3 {
  margin-bottom: 34px;
  margin-top: 20px;
  text-align: center;
  color: var(--grey);
}
.Prosandcons__cons h3 {
  margin-bottom: 34px;

  margin-top: 20px;
  text-align: center;
  color: var(--grey);
}
.Prosandcons__pros__what {
  margin-right: 18px;
  margin-left: 18px;
  font-weight: 600;
  color: var(--grey);
}
.Prosandcons__cons__what {
  margin-right: 18px;
  margin-left: 18px;
  font-weight: 600;
  color: var(--grey);
}
.Prosandcons__pros__what p span {
  color: #e87975;
}
.Prosandcons__cons__what p span {
  color: #68a530;
}

.Prosandcons__cons {
  background-color: #192309;
  width: 450px;
  height: 626px;
  border-radius: 10px;
}

.Prosandcons__bonjour p {
  margin-top: 130px;
  margin-bottom: 50px;
  font-family: "Annie Use Your Telescope";
  text-align: center;
  color: var(--dark);
  font-size: 24px;
  rotate: -4deg;
}
.Prosandcons__sentence__arrow__mobile {
  display: none;
}
@media screen and (max-width: 1000px) {
  .Prosandcons__sentence__arrow__mobile {
    display: contents;
  }
  .Prosandcons__sentence__arrow__mobile {

    display: flex;
    justify-content: center;
    margin-top: 18px;
    flex-direction: row;
    margin-left: 10px;
  }
  .Prosandcons__sentence__arrow__txt__mobile p {
    font-weight: 600;
 
  }
  .Prosandcons__sentence__arrow__svg__mobile {
    position: relative;
    z-index: 100;
    scale: 0.8;
    margin-top: 19px;
    rotate: 11deg;
  }
  .Prosandcons__container {
    position: relative;
    margin-top: -20px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Prosandcons__pros {
    margin-right: 16px;
    margin-left: 16px;
    width: auto;
    background-color: #2b1b09;
    margin-top: 150px;
    padding-bottom: 32px;
    height: 100%;
    border-radius: 10px;
  }
  .Prosandcons__sentence__arrow {
    display: none;
  }
  .Prosandcons__cons {
    background-color: #192309;
    margin-top: 24px;
    margin-right: 16px;
    margin-left: 16px;
    width: auto;
    padding-bottom: 32px;
    height: 100%;
    border-radius: 10px;
  }
}
