.Cookie__popup {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  height: fit-content;
  width: 100%;
  background-color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  color: rgb(255, 254, 254);
  z-index: 1000;

}


.Cookie__popup__blocks {
  display: flex;
  flex-direction: row;
}

.Cookie__popup__leftblock {
  flex: 3;
  margin-right: 10px;
  margin-left: 24px;
}

.Cookie__popup__rightblock {
  margin-right: 24px;
  margin-top: 24px;
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: row;
}
.underline__cookies {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(0, 183, 255);
}
.cookie-button_refuse {
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 38px;
  margin-right: 18px;
  color: rgb(255, 255, 255);
  border: 1px rgb(255, 255, 255) solid;
}
.cookie-button_accept {
  margin-bottom: 24px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  height: 40px;
  background-color: #ff6b00;
  color: white;
}
.cookie-button_refuse > p {
  padding-right: 0px;
  font-size: 18px;

  margin-top: 8px;
}
.cookie-button_accept > p {
  padding-right: 0px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 8px;
}

@media screen and (max-width: 1100px) {
  .Cookie__popup__blocks {
    display: flex;
    flex-direction: column;
  }
  .Cookie__popup__padding {
padding-inline: 24px;
  }
  .Cookie__popup__leftblock {
    flex: 3;
    margin-right: 4px;
    margin-left: 0px;
  }
}
