.CallToAction100Outlined {
  height: 54px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
background-color: white;
border: 2px solid #000000;

}
.CallToAction100Outlined p {
  color: rgb(0, 0, 0);
  letter-spacing: 0em;
  font-size: 18px;
  font-weight: 600;
  margin-top: 14px;
}

.CallToAction_icon {
  margin-top: 15px;
  margin-left: 6px;
}
.CallToAction100.disabled {
  background-color: rgb(206, 206, 206);
  cursor: not-allowed;
  opacity: 0.2;
}
