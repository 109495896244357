.PricingYoungs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Price__offer1 {
  height: 450px;
  width: 351px;
  border-radius: 10px;
  background-color: var(--dark);
}
.Price__offer2 {
  height: 450px;
  width: 351px;
  border-radius: 10px;
  background-color: var(--dark);
}
.Price__offer3 {
  height: 450px;
  width: 351px;
  border-radius: 10px;
  background-color: var(--dark);
}

.Price__offer__container {
  margin-left: 21px;
  margin-right: 21px;
  margin-top: 18px;
}
.Price__offer__logo img {
  height: 25px;
  opacity: 0.8;
}

.Price__offer__coursetitle {
  color: #ffddd2;
  margin-top: -16px;
}
.Price__offer__coursetitle2 {
  color: #d3d2ff;
  margin-top: -16px;
}
.Price__offer__coursetitle3 {
  color: #fffdd2;
  margin-top: -16px;
}
.Price__offer__courseprice p {
  letter-spacing: -1px;
  margin-top: -13px;
  font-size: 16px;
  font-weight: 700;
}

.Price__offer__greyline {
  height: 1px;
  background-color: #d1cbc620;
  width: 100%;
}

.Price__offer__bulletpoints {
  margin-top: 22px;
  margin-right: -5px;
  margin-left: 14px;
}
.Price__offer__bulletpoints p {
  font-size: 16px;
  letter-spacing: -1px;
  margin-bottom: -12px;
  font-weight: 700;
}
.Price__offer__levels {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Price__offer__level {
  outline: 1px #474544 solid;
  border-radius: 5px;
  width: 70px;
  height: 26px;
  cursor: pointer;
}

.Price__offer__level p {
  text-align: center;
  color: #d1cbc6;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -1px;
}

.Price__offer__level.selected {
  background-color: #474544; /* Change to desired color */
  color: #ffffff; /* Adjust text color if needed */
}

.Price__offer__cta {
  margin-top: 38px;
  display: flex;
  justify-content: center;
}
.Price__offer__cta2 {
  margin-top: 94px;
  display: flex;
  justify-content: center;
}

.Price__offer__terms p {
  font-size: 12px;
  letter-spacing: -0.5px;
  color: #d1cbc6;
  text-decoration: underline;
  margin-top: 40px;
  text-align: center;
}

@media screen and (max-width: 840px) {
  .PricingYoungs {
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .Price__offer1 .Price__offer2 .Price__offer3 {
    height: 450px;
    width: 100%;
    border-radius: 10px;
  }
}
