@import url("https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap");

.Comparison {
  margin-top: 150px;
  background-color: white;
}
.Comparison__title {
}
.Comparison__redbackground {
  position: relative;
  z-index: 99;
  background-color: #954446;
  margin-top: -57px;
  margin-left: 498px;
  height: 33px;
  width: 85px;
  rotate: -2deg;
}
.Comparison__positioning__redblock {
  display: flex;
  justify-content: center;
}
.Comparison__title__h2 {
  display: flex;
  justify-content: center;
}
.Comparison__title__h2 h2 {
  position: relative;
  z-index: 100;
  color: var(--dark);
}
.Comparison__title__h2 span {
color: white;
}

.Comparison p {
  font-family: "Annie Use Your Telescope";
}

.Comparison__subtitle {
  display: flex;
  margin-top: -30px;
  margin-left: 500px;
  justify-content: center;
  flex-direction: row;
}
.Comparison__subtitle1 p {
  font-size: 15px;
  color: var(--dark);
  rotate: 4deg;
  margin-right: 24px;
}
.Comparison__subtitle2 p {
  font-size: 15px;
  color: var(--dark);
  rotate: -4deg;
}
@media screen and (max-width: 700px) {
  .Comparison__title__h2 {
    display: flex;
    justify-content: center;


   
    text-align: center;
  }
  .Comparison__title__h2 h2 {
    position: relative;
    width: 300px;
    z-index: 100;

  }
  .Comparison__positioning__redblock {
    display: flex;
    background-color: red;
    justify-content: center;
  }
  .Comparison__redbackground {
    position: relative;
    z-index: 99;
    background-color: #954446;
    margin-top: -57px;
    margin-left: 14px;
    height: 33px;
    width: 85px;
    rotate: -2deg;
  }
  .Comparison__subtitle {
    display: flex;
    margin-top: -30px;
    margin-left: 14px;
    justify-content: center;
    flex-direction: row;
  }

}