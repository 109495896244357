.Pricing {
  background-color: black;
  margin-top: 70px;
  padding-top: 100px;
  padding-bottom: 100px;
  color: white;
  padding-inline: 160px;
}


@media screen and (max-width: 840px) {
  .Pricing {
    background-color: black;
    margin-top: 70px;
    padding-top: 100px;
    padding-bottom: 100px;
    color: white;
    padding-inline: 0px;
  }
}

.Pricing__buttons {
  margin-bottom: 20px;
}

.Pricing__carousel {
  overflow-x: hidden; /* Désactiver le défilement horizontal avec la souris */
  display: flex;
  scroll-snap-type: x mandatory;
  width: 100%;

  scroll-behavior: smooth; /* Enable smooth scrolling by default */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.Pricing__carousel::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Pricing__carousel:active {
  cursor: grabbing;
}

.PriceSwitch {
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
}

.PriceSwitch__button__container {
  position: relative;
  display: flex;
  width: 260px;
  background-color: #242424;
  outline: 4px #242424 solid;
  border-radius: 100px;
  height: 34px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.PriceSwitch button {
  position: relative;
  flex: 1;
  color: rgb(0, 0, 0);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  z-index: 1;
  font-weight: 600;
}

.PriceSwitch button p {
  letter-spacing: -1px;
}

.PriceSwitch__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  background-color: #d9d9d9;
  border-radius: 100px;
  transition: transform 0.1s ease;
}

.PriceSwitch button.active {
  color: #2b2b2b; /* Changez la couleur du texte pour le bouton actif si besoin */
}

.Pricing__blocks {
  display: flex;
  width: 100%; /* Trois blocs donc 300% */
}
.Pricing__block {
  min-width: 100%;
  height: 450px;
}

.Pricing__dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 42px;
  margin-bottom: 80px;
}
.Pricing__dot {
  background-color: #242424;
  width: 5px;
  height: 5px;
  border-radius: 10px;
}

.Pricing__dot--active {
  background-color: white;
}

.Pricing__dot__space {
  width: 10px;
}
