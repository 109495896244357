

.V1Contact__div {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
}
.Contactzone__heading p {
  margin-top: 120px;
  letter-spacing: -0.03em;

  font-size: 55px;
  line-height: 1;
}
.Contactzone__txt p {

  font-size: 16px;
  margin-top: -24px;
  margin-bottom: 30px;
  letter-spacing: -0.00em;
margin-right: 16px;
  margin-bottom: 20px;
}

.spanned {
  color: #ff6b00;
  font-weight: 600;
}

.V1Contact__datacontact > p {
  font-size: 15px;
  width: 500px;
}

.V1Contact__questionlabel,
.V1Contact__textlabel {
  margin-top: 24px;

  color: gray;
}

.V1Contact__inputs {
  width: 600px;
}

.V1Contact__textinput input {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

  width: calc(100% - 16px);
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 8px;

  height: 20px;
  text-decoration: none;
  left: 24px;
}

.V1Contact__consent {
  color: #888888;
  margin-top: 24px;
}

.V1Contact__container {
  display: flex;
  flex-direction: row;
}

.V1Contact__icon {
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
}

.V1Contact__text {
  font-size: 14px;
  margin-top: -8px;
  font-weight: 500;
  color: #6a6a6a;
}
.legaltxt p {
  font-size: 12px;
}

.V1Contact__icon input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.V1Contact__container {
  display: flex;
  align-items: center;
}
.V1Contact__legal {
  cursor: pointer;
  text-decoration: underline;
  color: #00a3ff;
}
.styled-dropdown {
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  width: calc(100% - 24px);
  background-color: white;
  border: none;
  border-radius: 8px;
  height: 40px;
  text-decoration: none;
  width: 100%;
  padding-left: 10px;
  appearance: none;
}

.styled-dropdown::-ms-expand {
  display: none;
}

.styled-dropdown option {
  padding-right: 20px;
}

.V1Contact__height > textarea {
  height: 200px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border: 0px;
  resize: none; /* Prevent textarea resizing by the user */
  width: calc(100% - 16px); /* Adjusting width and considering margin-right */
  margin-right: 16px;
  padding: 8px;
  border-radius: 8px;
}

.Captcha {
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
}

.V1CTA {
  cursor: pointer;
  display: flex;
  text-decoration: none;
  color: white;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 50px;
  background-color: #000000;
  border-radius: 100px;
  font-size: 16px;
  border: solid black 0px;
}

.space {
  margin-bottom: 180px;
}

.hover {
  cursor: pointer;
}

@media screen and (max-width: 840px) {
  .Contactzone__heading {
    margin-top: -50px;
    letter-spacing: -0.05em;

    font-size: 50px;
    line-height: 1;
    margin-left: 16px;

  }
  .V1Contact__div {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }


  .V1Contact__inputs {
    width: auto;
    padding-inline: 20px;
  }
  .ContactComponent_button {
display: flex;
justify-content: center;
  }
  .V1Contact__textlabel {


  }
  .Contactzone__txt {
    margin-left: 16px;
  }
}
