.Payment {
  padding-inline: 100px;
}

.Payment__course {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 16px;
}
.Payment__fees {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 16px;
}

.Payment__block {
  display: flex;
  flex-direction: row;
}
.Payment__left {
  margin-right: 24px;
  flex: 2;
}
.Payment__right {
  flex: 1;
}

.Payment__secure {
  color: grey;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Payment__icon {
  scale: 0.7;
  margin-top: 13px;
}
.Payment__img img {
  height: 40px;
}
.Payment__img {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.Payment_line {
  display: flex;
  margin-top: -20px;
  flex-direction: row;
}
.Payment_line__total {
  margin-left: auto;
}
@media screen and (max-width: 840px) {
  .Payment {
    padding-inline: 0px;
    margin-inline: 24px;
  }
  .Payment__block {
    display: flex;
    flex-direction: column;
  }
  .Payment__left {
    margin-right: 0px;
    flex: 2;
  }
}
