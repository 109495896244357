.OurClasses {
  background-color: var(--lightgrey);


}

.OurClasses__h2 h2 {
  padding-top: 52px;
  margin-bottom: 60px;
  color: var(--dark);
  text-align: center;
}

.OurClasses__CardClass {
  padding-inline: 160px;
  display: flex;
  flex-direction: row;
}

.OurClasses__18px__horizontal__space {
  width: 60px;
  height: 18px;
}
.OurClasses__viewmore {
  text-align: center;
  text-decoration: underline;
  color: grey;
  margin-top: 50px;
  padding-bottom: 100px;
}

@media screen and (max-width: 1285px) {
  .OurClasses__CardClass {
    padding-inline: 300px;
    flex-direction: column;
  }
}

@media screen and (max-width: 900px) {
  .OurClasses__CardClass {
    padding-inline: 16px;
    flex-direction: column;
  }
}
