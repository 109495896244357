.Frenchquizlanding__img img {
  width: 100%;
}
.Frenchquizcontent {
  padding-inline: 120px;
}

.Frenchquiz__objectives__icon {
  margin-top: 15px;
  color: var(--primary);
  scale: 0.8;
  margin-right: 4px;
}
.Frenchquiz__objectives {
  margin-bottom: -20px;
  display: flex;
  flex-direction: row;
}
.Frenchquiz__cta__placement {
  max-width: 320px;
margin-left: auto;
}

@media screen and (max-width: 840px) {
  .Frenchquizcontent {
    padding-inline: 24px;
  }
  .Frenchquiz__objectives__icon {
    margin-top: 10px;
    color: var(--primary);
    scale: 0.8;
    margin-right: 4px;
  }
  .Frenchquiz__cta__placement {
    max-width: 100%;
  margin-left: auto;
  }
}
