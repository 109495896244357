.LoadingComponent {
  background-color: var(--primary);
  height: 100vh;
}
.LoadingComponent__centercontent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; /* Centrage vertical */
  height: 100vh; /* Assurez-vous que le conteneur prend toute la hauteur de la fenêtre */
}

.LoadingComponent__centercontent img {
  margin-top: -80px;
  width: 150px;
  margin-bottom: 40px;
}

.LoadingComponent__bar {
  background-color: #ffffff68;

  width: 250px;
  height: 2px;
  border-radius: 100px;
  position: absolute;
}
.LoadingComponent__bar__loaded {
  background-color: rgb(255, 255, 255);
  height: 2px;
  border-radius: 100px;
  width: 1px;
  animation: loadingAnimation 1s linear forwards;
}

@keyframes loadingAnimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
